require("jquery")
require("@rails/ujs").start()
require("@rails/activestorage").start()


require.context('../assets/images', true)

// import '../assets/css/app.min.css'
// import '../assets/css/metisMenu.min.css'
import '../assets/css/vendor/bootstrap.min.css'
import '../assets/css/vendor/owl.carousel.min.css'
import '../assets/css/vendor.min.css'
import '../assets/css/style.css'

